import { cssBundleHref } from '@remix-run/css-bundle';
import { LoaderArgs, type LinksFunction, redirect } from '@remix-run/node';
import { Outlet, useLoaderData } from '@remix-run/react';
import { IUser } from '@shiftsmartinc/shiftsmart-types';
import { ApplicationHTML } from '@shiftsmartinc/remix-utils';
import invariant from 'tiny-invariant';
import { typedjson } from 'remix-typedjson';
import { canAccessApplication } from '~/global/utils/security';
import { getGraphql } from '~/global/utils/graphql';
import { getUserSession } from '~/global/actions/getUserSession';
import { GlobalApplication } from '~/global/components/GlobalApplication';
import { links as manifestLinks } from '~/global/data/manifest';
import {
  LoaderRootDocument,
  LoaderRootUserFragment } from
'~/__generated__/sdk';
import { redirectLogout } from '~/global/actions/redirectLogout';
import stylesheet from '~/global/styles/index.css';

/**
 * @external https://remix.run/docs/en/route/links
 * @description The links function defines which <link> elements to add to
 * the page when the user visits a route.
 */
export const links: LinksFunction = () => {
  const favicon = '/branding/shiftsmart/favicon.ico';
  const icon = '/branding/shiftsmart/icons/icon-192x192.png';
  const manifest = `/manifest.json`;

  return [
  // Stylesheets
  ...(cssBundleHref ? [{ href: cssBundleHref, rel: 'stylesheet' }] : []),
  { href: stylesheet, rel: 'stylesheet', type: 'text/css' },

  // Links for branding
  ...manifestLinks,
  { href: icon, rel: 'apple-touch-icon' },
  { href: favicon, rel: 'favicon' },
  { href: favicon, rel: 'icon', type: 'image/svg+xml' },
  { href: favicon, rel: 'mask-icon', type: 'image/svg+xml' },
  { href: manifest, rel: 'manifest', type: 'application/manifest+json' },

  // Google Fonts
  {
    href: `https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap`,
    rel: 'stylesheet',
    type: 'text/css'
  }];

};

export interface RootLoaderData {
  accessToken: string;
  companyIds: string[];
  env: {
    APP_ENV: string;
    APP_NAME: string;
    APP_VERSION: string;
    PLATFORM_API_URI: string;
    VERCEL_GIT_COMMIT_SHA?: string;
  };
  timeZone: string;
  user?: LoaderRootUserFragment;
}

/**
 * @external https://remix.run/docs/en/route/loader
 * @external https://remix.run/docs/en/guides/envvars
 */
export const loader = async (args: LoaderArgs) => {
  const { request } = args;

  const url = new URL(request.url);
  const isAuthRoute = url.pathname.includes('/auth');

  const APP_ENV = process.env['APP_ENV'];
  const APP_NAME = process.env['APP_NAME'];
  const APP_VERSION = process.env['APP_VERSION'];
  const PLATFORM_API_URI = process.env['PLATFORM_API_INTERNAL'];
  const VERCEL_GIT_COMMIT_SHA = process.env['VERCEL_GIT_COMMIT_SHA'];

  invariant(APP_ENV, `APP_ENV is not set.`);
  invariant(APP_NAME, `APP_NAME is not set.`);
  invariant(APP_VERSION, `APP_VERSION is not set.`);
  invariant(PLATFORM_API_URI, `PLATFORM_API_URI is not set.`);

  const userSession = await getUserSession(request);
  const { accessToken, companyIds, timeZone, userId } = userSession;

  const dataLoader: RootLoaderData = {
    accessToken,
    companyIds,
    env: {
      // 🚧 These "env" environment variables are exposed to the client
      APP_ENV,
      APP_NAME,
      APP_VERSION,
      PLATFORM_API_URI,
      VERCEL_GIT_COMMIT_SHA
    },
    timeZone,
    user: undefined
  };

  /**
   * We don't have a user session to look the user up by, at this point
   * all we can do is redirect to the auth route. If we're already on it,
   * we return the dataLoader w/o a user.
   */
  if (!userId && !isAuthRoute) return redirect('/auth');
  if (!userId) return typedjson(dataLoader);

  const graphql = await getGraphql(request);
  const { data, error } = await graphql.
  query(LoaderRootDocument, { userId }).
  toPromise();

  if (error) throw new Response('error.message');
  if (!data) throw new Response('error.message');

  const user = data.user.data;

  if (!user.uuid && !isAuthRoute) return redirect('/auth');
  if (user.uuid && isAuthRoute) return redirect('/');

  const isAllowed = canAccessApplication(user);

  if (!isAllowed && !isAuthRoute) return redirectLogout(request);

  return typedjson({ ...dataLoader, user });
};

/**
 * @external https://remix.run/docs/en/file-conventions/root
 * @description This is the root context + our application
 */
export default function App() {
  // Hooks
  const data = useLoaderData<typeof loader>();

  // Setup
  const { env, user } = data;

  // Handlers

  // Markup

  // Life Cycle
  // useDatadogUserContext(user);

  // 🔌 Short Circuits

  return (
    <ApplicationHTML<RootLoaderData['env']> env={env}>
      <GlobalApplication user={((user as unknown) as IUser)}>
        <Outlet />
      </GlobalApplication>
    </ApplicationHTML>);

}